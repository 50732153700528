import { AppRole } from '@app/pages/auth/shared/interfaces/auth.interface';

/**
 * ACL Type.
 */
interface AclType {
    [name: string]: (AppRole | '*')[];
}

/**
 * ACL Value.
 */
export const ACL: AclType = {
    auth: ['*'],
    dashboard: ['admin','superuser', 'manager', 'agent'],
    customer: ['admin','superuser', 'manager', 'agent'],
    customerListDetail: ['admin','superuser', 'manager', 'agent'],
    historyTrips: ['admin','superuser', 'manager', 'agent'],
    detailTrips: ['admin','superuser', 'manager', 'agent'],
    inventories: ['admin','superuser', 'manager'],
    messaging: ['admin','superuser', 'manager', 'agent'],
    detailModify: ['admin','superuser', 'manager', 'agent'],
    paymentHistory: ['admin','superuser', 'manager', 'agent'],
    myProfile: ['admin','superuser', 'manager', 'agent'],
    profiles: ['admin','superuser', 'manager', 'agent'],
    companyInformation: ['admin','superuser'],
    bankingInformation: ['superuser'],
    dynamicPricing: ['admin','superuser'],
    users: ['admin','superuser'],
    changePassword: ['admin','superuser', 'manager', 'agent'],
    operatorSettings: ['admin','superuser'],
    userSettings: ['admin', 'superuser', 'agent'],
    changeUserPassword: ['admin', 'superuser', 'agent'],
    userProfile: ['admin', 'superuser', 'agent'],
};
