export const environment = {
  production: true,
  google_maps_key: "AIzaSyB9PC-QkIrgcsJBX9M5OBhnCCOpw2JDhcc",
  uploadUrl: 'https://buslane-upload-storage-sqdyjvm7uq-uc.a.run.app',
  apiUrl: "https://uat-buslane-python-api-gc656ktdia-uc.a.run.app",
  apiPaymentUrl: "https://dev-buslane-node-api-gc656ktdia-uc.a.run.app",
  apiRoute: 'https://uat-booking-engine-api-node-gc656ktdia-uc.a.run.app',
  operatorID: '9aed9dfd-6fa9-4b86-90ef-a1f5692d7df2',
  APP_ID: 'tN3Z80AL',
  APP_SECRET: 'sk_test_GSq32meu9FsfBDnsxo1hNUxZdy5cMln4',
  STRIPE_ID: 'ca_JgDnVGOm6oYrZ0X4jR0ZbELjkZSq3WKN',
  OP_URL: 'https://demo-op.buslane.com',
  conversationIDSuffix: '_UAT'

};
