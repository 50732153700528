import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { IInventory } from '@app/pages/inventories/shared/interfaces/inventory.interface';
import { IFeature } from '@app/pages/inventories/shared/interfaces/feature.interface';
import { AuthService } from '@app/pages/auth/shared/services/auth.service';
import { IPaging } from '@shared/interfaces/paging.interface';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  private vehicles$: BehaviorSubject<IInventory[]>;
  public vehicles: Observable<IInventory[]>;

  private vehiclePaging$: BehaviorSubject<IPaging>;
  public vehiclePaging: Observable<IPaging>;

  private vehiclesFeatures$: BehaviorSubject<IFeature[]>;
  public vehiclesFeatures: Observable<IFeature[]>;

  private vehicleTypes$: BehaviorSubject<IFeature[]>;
  public vehicleTypes: Observable<IFeature[]>;

  constructor(
    private http: HttpClient,
    private userAuthenticationService: AuthService
  ) {
    this.vehicles$ = new BehaviorSubject<IInventory[]>(null);
    this.vehicles = this.vehicles$.asObservable();

    this.vehiclePaging$ = new BehaviorSubject<IPaging>(null);
    this.vehiclePaging = this.vehiclePaging$.asObservable();

    this.vehiclesFeatures$ = new BehaviorSubject<IFeature[]>(null);
    this.vehiclesFeatures = this.vehiclesFeatures$.asObservable();

    this.vehicleTypes$ = new BehaviorSubject<any[]>(null);
    this.vehicleTypes = this.vehicleTypes$.asObservable();
  }

  getAll(filter: any, filterBody: any) {
    const currentUser = this.userAuthenticationService.authValue;

    const filterParams = Object.entries(filter).reduce(
      (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
      {}
    );
    const params = new HttpParams({ fromObject: filterParams }).toString();

    return this.http
      .post<IInventory[]>(
        `${environment.apiUrl}/api/v1/vehicles/${currentUser.operator_id}?${params}`,
        { ...filterBody }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            this.vehicles$.next(response.data);

            const paging: IPaging = {
              ...response.data,
              total_data: response.total_data,
            };

            this.vehiclePaging$.next(paging);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  getFeatures() {
    return this.http
      .post<IFeature[]>(`${environment.apiUrl}/api/v1/vehicle-features`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.vehiclesFeatures$.next(response);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }


  getVehicleFeatures() {
    const endPoint = `${environment.apiUrl}/api/v1/operators/vehicles/features`;
    return this.http.post<any>(endPoint, {});
  }


  getVehicleTypes() {
    return this.http
      .get(`${environment.apiUrl}/api/v1/vehicles/buslane-vehicle-types`, {})
      .pipe(
        map((response: any) => {
          if (response) {
            this.vehicleTypes$.next(response);
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  updateVehicleType(vehicleId: any, buslaneVehicleTypeId: any, bus_name: string) {
    return this.http
      .post(
        `${environment.apiUrl}/api/v1/vehicles/${vehicleId}/buslane-vehicle-type`,
        { buslaneVehicleTypeId, operator_vehicle_type: bus_name }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  addFeatures(vehicleId: string, featureId: number) {
    return this.http
      .post<IInventory>(
        `${environment.apiUrl}/api/v1/vehicles/${vehicleId}/add-features`,
        { vehicle_feature_id: featureId }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  removeFeatures(vehicleId: string, featureId: number) {
    return this.http
      .post<IInventory>(
        `${environment.apiUrl}/api/v1/vehicles/${vehicleId}/remove-features`,
        { vehicle_feature_id: featureId }
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  updateVehicleAvailable(vehicleId: any) {
    return this.http
      .post(
        `${environment.apiUrl}/api/v1/operators/vehicles/${vehicleId}/set-available`,
        {}
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  updateVehicleHeroImage(vehicleId: any) {
    return this.http
      .post(
        `${environment.apiUrl}/api/v1/operators/vehicles/${vehicleId}/set-hero-image`,
        {}
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  uploadAttachment(formData: any) {
    return this.http
      .post(`${environment.apiUrl}/api/v1/upload/attachment`, formData)
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
          return response.errorMessage
        })
      );
  }

  updateVehicleAttachment(file: any, id: any, is_hero_img) {
    return this.http
      .post(`${environment.apiUrl}/api/v1/vehicles/${id}/update-image`, {
        img_url: file,
        is_hero_img: is_hero_img,
      })
      .pipe(
        map((res: any) => {
          if (res) {
            return res;
          }

          throw new Error(res.errorMessage);
        })
      );
  }
  updateVehicleNameOrSeat(vehicleId: any, payload : any) {
    return this.http
      .put(
        `${environment.apiUrl}/api/v1/vehicles/update-vehicle/${vehicleId}`,
        payload
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }

  deleteVehicle(vehicleId: number) {
    return this.http
      .delete<IInventory>(
        `${environment.apiUrl}/api/v1/vehicles/delete/${vehicleId}`
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  addVehicle(payload: any) {
    return this.http
      .post<IInventory>(
        `${environment.apiUrl}/api/v1/vehicles/add-vehicle`,
        payload
      )
      .pipe(
        map((response: any) => {
          if (response) {
            return response;
          }

          throw new Error(response.errorMessage);
        })
      );
  }
}
