import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-itinerary-modal',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPageComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService
  ) {}

  continue: boolean = false;
  public resetForm: FormGroup;
  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  handleReset() {

    
    if(this.resetForm.valid){

      this.authService
      .forgotPassword(
        this.resetForm.value['email']
      )
      .subscribe(
        (data) => {
          this._snackBar.open(`${data.message}`, 'close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
          setTimeout(() => {
            this.router.navigate(['/reset-password'],{ queryParams: { email: this.resetForm.value['email'] } });
          }, 1000);
        }, 
        (error) => {
          this._snackBar.open(`${error.error.message}`, 'close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
          });
        }
      );
    }
  }

  openLogin() {
    this.router.navigate(['/auth/signin']);
  }
}
