import Talk from 'talkjs';
import { APP_ID, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { QuoteService } from '@app/pages/dashboard/shared/services/quote.service';

@Injectable({
  providedIn: 'root',
})
export class TalkService {
  constructor(private http: HttpClient, private quoteService: QuoteService) {}
  private currentUser: Talk.User;
  private userInfo: any;
  async createUser(applicationUser: any) {
    await Talk.ready;
    return new Talk.User({
      id: applicationUser.id,
      name: applicationUser.username,
      photoUrl: applicationUser.photoUrl,
      role: applicationUser.role,
      email: applicationUser.email,
    });
  }
  async createCurrentSession() {
    await Talk.ready;
    const userLS = JSON.parse(
      localStorage.getItem('buslane-session') as string
    );
    let userDetails = userLS ? userLS : null;
    userDetails = {
      id: userDetails.id,
      username: `${userDetails.first_name} ${userDetails.last_name}`,
      email: userDetails.email,
      role: 'seller',
      photoUrl: userDetails.img_profile,
    };
    this.userInfo = userDetails;
    this.currentUser = await this.createUser(userDetails);
    const session = new Talk.Session({
      appId: environment.APP_ID,
      me: this.currentUser,
    });
    return session;
  }
  private envSpecificName(originalName: string) {
    return `${originalName}${environment?.conversationIDSuffix}`
  }
  private async getOrCreateConversation(
    session: Talk.Session,
    tripID: number,
    customerDetails: any | null
  ) {
    let conversation;
    let bookingSource = customerDetails.is_marketplace ? 'Marketplace' : 'Whitelabel';
    if (tripID !== 0) {
      const conversationName = this.envSpecificName(`conversation_${tripID}`)
      conversation = session.getOrCreateConversation(conversationName);
    } else {
      //DEPRECATED: shouldnt be reachable
      conversation = session.getOrCreateConversation(
        `conversations_${this.userInfo.email}`
      );
      localStorage.setItem(
        'current_conversation',
        `conversations_${this.userInfo.email}`
      );
    }
    if (conversation) {
      conversation.setParticipant(this.currentUser);
      conversation.setAttributes({
        subject: `Trip #${tripID} - FAQ`,
        custom: {
          itemId: `${tripID}`,
          source: `${bookingSource}`
        },
      });
    }
    return conversation;
  }
  async createInbox(
    session: Talk.Session,
    conversationID: number,
    listOPUsers: any = [],
    customerDetails: any
  ) {
    const conversation = await this.getOrCreateConversation(
      session,
      conversationID,
      customerDetails
    );
    await Promise.all(
      listOPUsers.map(async (i: any) => {
        const user = {
          id: i.id,
          email: i.email,
          username: `${i.first_name} ${i.last_name}`,
          role: 'seller',
        };
        const otherUser = await this.createUser(user);
        conversation?.setParticipant(otherUser);
      })
    );
    let [firstName, lastName] = customerDetails.user.name.split(' ');
    const customerUserPayload = {
      id: customerDetails.user_id,
      email: customerDetails.email,
      username: `${firstName} ${lastName}`,
      role: 'buyer2',
    };
    const customerUser = await this.createUser(customerUserPayload);
    conversation?.setParticipant(customerUser);
    const inbox = session.createChatbox({ showChatHeader: false });
    inbox.select(conversation);
    return inbox;
  }
  async autoSend(session: Talk.Session, tripID: number, user: any) {
    const otherApplicationUser = {
      id: `${tripID}_${user.userID}`,
      username: user.user.name,
      email: user.user.email,
      role: 'buyer',
    };
    const otherUser = await this.createUser(otherApplicationUser);
    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(this.currentUser, otherUser)
    );
    conversation.sendMessage('Auto Send Message');
    return conversation;
  }
  async createPopup(session: Talk.Session, listUser: any) {
    const newListUser = listUser.map((i: any) => ({
      ...i,
      access: 'Read',
    }));
    const conversation = await this.getOrCreateConversation(session, 0, null);
    await Promise.all(
      newListUser.map(async (i: any) => {
        const user = {
          id: i.id,
          email: i.email,
          username: `${i.first_name} ${i.last_name}`,
          role: 'seller',
        };
        const otherUser = await this.createUser(user);
        conversation?.setParticipant(otherUser, {
          access: i.access,
        });
        conversation.setAttributes({
          subject: `OP_${this.userInfo.email}`,
          custom: {
            isPopupOP: '1',
            source: 'OperatorPortal',
          },
        });
      })
    );
    const popup = session.createPopup();
    popup.select(conversation);
    popup.mount({ show: false });
    return popup;
  }
  updateChatBoxStatus(status: boolean) {
    return this.http.put(
      `${environment.apiUrl}/api/v1/operators/${environment.operatorID}/change-chat-box-status`,
      { chat_box_status: status }
    );
  }
  joinInChat(isJoinIn: Boolean) {
    return this.http.put(
      `${environment.apiUrl}/api/v1/operators/${environment.operatorID}/change-chat-box-status`,
      { chat_box_status: isJoinIn }
    );
  }
  getChatBoxStatus() {
    return this.http.get(
      `${environment.apiUrl}/api/v1/operators/${environment.operatorID}/get-chat-box-status`
    );
  }
  setPermissionUser(info: any) {
    return this.http.put(
      `${environment.apiUrl}/api/v1/operators/set-permissions`,
      {
        userId: this.currentUser.id,
        conversationId: info.conversationId,
        access: info.access,
      }
    );
  }
  onOffDutyStatus(status: boolean) {
    return this.http.put(
      `${environment.apiUrl}/api/v1/operators/${this.userInfo.id}/change-duty-status`,
      { duty_status: status, userId: this.userInfo.id.toString() }
    );
  }
  getDutyStatus() {
    return this.http.get(
      `${environment.apiUrl}/api/v1/operators/${this.userInfo.id}/get-duty-status`
    );
  }
  getUnreadMessage(userID: number) {
    return this.http.get(
      `${environment.apiUrl}/api/v1/operators/${userID}/get-unread-conversations`
    );
  }
  getListUserOperator() {
    return this.http.get(
      `${environment.apiUrl}/api/v1/operators/operatorsList`
    );
  }
  getOperatorChatBox() {
    return this.http.get(
      `${environment.apiUrl}/api/v1/operators/get-chat-box-status`
    );
  }
  sendSystemMessage(payload) {
    return this.http.put(
      `${environment.apiUrl}/api/v1/operators/send-system-message`,
      payload
    );
  }
  sendChatMail(payload) {
    return this.http.get(
      `${environment.apiUrl}/api/v1/operators/send-system-message`
    );
  }
  getListAdminUser() {
    return this.http.get(
      `${environment.apiUrl}/api/v1/users/admin-user-duty-status`
    );
  }

  getUnreadMessages(userID: number, limit: number, startingAfter?: string) {
    if (startingAfter) {
      return this.http.get(
        `https://api.talkjs.com/v1/${environment.APP_ID}/users/${userID}/conversations?limit=${limit}&unreadsOnly=true&startingAfter=${startingAfter}`,
        {
          headers: {
            Authorization: `Bearer ${environment.APP_SECRET}`,
          },
        }
      );
    } else {
      return this.http.get(
        `https://api.talkjs.com/v1/${environment.APP_ID}/users/${userID}/conversations?limit=${limit}&unreadsOnly=true`,
        {
          headers: {
            Authorization: `Bearer ${environment.APP_SECRET}`,
          },
        }
      );
    }
  }

  getAllMessages(userID: number, limit: number, startingAfter?: string) {
    if (startingAfter) {
      return this.http.get(
        `https://api.talkjs.com/v1/${environment.APP_ID}/users/${userID}/conversations?limit=${limit}&startingAfter=${startingAfter}&lastMessageAfter=0000000000000`,
        {
          headers: {
            Authorization: `Bearer ${environment.APP_SECRET}`,
          },
        }
      );
    } else {
      return this.http.get(
        `https://api.talkjs.com/v1/${environment.APP_ID}/users/${userID}/conversations?limit=${limit}&lastMessageAfter=0000000000000`,
        {
          headers: {
            Authorization: `Bearer ${environment.APP_SECRET}`,
          },
        }
      );
    }
  }

  getMessageByID(conversationID: string) {
    return this.http.get(
      `https://api.talkjs.com/v1/${environment.APP_ID}/conversations/conversation_${conversationID}?lastMessageAfter=0000000000000`,
      {
        headers: {
          Authorization: `Bearer ${environment.APP_SECRET}`,
        },
      }
    );
  }
}
