<mat-icon
  mat-dialog-close
  (click)="toggleDialog(false)"
  style="float: right; cursor: pointer"
  >close</mat-icon
>
<div>
  <div class="flex flex-col mb-4">
    <p class="text-xl">Add New Inventory</p>
    <form [formGroup]="form">
      <div class="column">
        <div class="content-left w-full sm:flex sm:flex-row sm:justify-between">
          <div class="w-full">
            <div class="grid sm:gap-4 gap-2">
              <div class="blocks">
                <mat-form-field
                  class="example-form-field w-full"
                  appearance="fill"
                >
                  <mat-label>Name*:</mat-label>
                  <input matInput type="text" formControlName="vehicle_name" />
                </mat-form-field>
                <div
                  class="text-danger error b22 password m9"
                  *ngIf="
                    form.get('vehicle_name')?.touched &&
                    form.get('vehicle_name')?.errors?.required
                  "
                >
                  Name is required field.
                </div>
              </div>
              <div class="blocks">
                <div class="flex items-center">
                  <div class="font-bold">Seats*:</div>
                  <div class="range-container">
                    <button
                      mat-fab
                      type="button"
                      (click)="decreaseSeat()"
                      [disabled]="form.controls.seats.value < 2"
                    >
                      -
                    </button>
                    <input
                      matInput
                      type="number"
                      formControlName="seats"
                      max="100"
                      min="1"
                    />
                    <button
                      mat-fab
                      type="button"
                      (click)="increaseSeat()"
                      [disabled]="form.controls.seats.value === 99"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex gap-6">
                <div class="font-bold">Types*:</div>
                <mat-radio-group
                  color="primary"
                  class="flex flex-col gap-1"
                  formControlName="buslane_vehicle_type_id"
                >
                  <mat-radio-button
                    *ngFor="let c of BUS_TYPES; let x = index"
                    [value]="c?.id"
                    class="mb-1"
                    >{{ c?.vehicle_type_name }}</mat-radio-button
                  >
                </mat-radio-group>
                <div
                  class="text-danger error b22 password m9"
                  *ngIf="
                    form.get('buslane_vehicle_type_id')?.touched &&
                    form.get('buslane_vehicle_type_id')?.errors?.required
                  "
                >
                  Type is required field.
                </div>
              </div>
              <div class="flex gap-6">
                <div class="font-bold">Amenities:</div>
                <div class="grid grid-cols-2 gap-x-4">
                  <div
                    *ngFor="let amenity of amenityOptions"
                    style="font-size: 14px"
                  >
                    <mat-checkbox
                      [value]="amenity.id"
                      (change)="onCheckChange($event)"
                    >
                      <div [innerHTML]="getAmenityTitle(amenity)"></div>
                    </mat-checkbox>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <label class="font-bold">Hero image on Buslane</label>
                  <mat-slide-toggle
                    style="margin-left: 25px"
                    formControlName="is_hero_img"
                  ></mat-slide-toggle>
                </div>
                <div>
                  <label class="font-bold">Available on Buslane</label>
                  <mat-slide-toggle
                    style="margin-left: 25px"
                    formControlName="is_available"
                  ></mat-slide-toggle>
                </div>
              </div>
              <!-- <div class="blocks">
                <div class="flex items-center">
                  <div class="font-bold">Quantity*:</div>
                  <div class="range-container">
                    <button
                      mat-fab
                      type="button"
                      (click)="decreaseQuantity()"
                      [disabled]="
                        form.controls.quantity.value < 2 || readonly_quantity
                      "
                    >
                      -
                    </button>
                    <input
                      matInput
                      type="number"
                      formControlName="quantity"
                      max="100"
                      min="1"
                      readonly
                      [readonly]="readonly_quantity"
                    />
                    <button
                      mat-fab
                      type="button"
                      (click)="increaseQuantity()"
                      [disabled]="
                        form.controls.quantity.value === 99 || readonly_quantity
                      "
                    >
                      +
                    </button>
                  </div>
                  <mat-checkbox
                    style="margin-left: 20px"
                    formControlName="is_unknown"
                    [disabled]="form.controls.quantity.value > 0"
                  >
                    <div>Unknown</div>
                  </mat-checkbox>
                </div>
              </div> -->
              <!-- <div class="flex gap-6">
                <div class="font-bold">Pricing*:</div>
                <div class="price-container gap-x-4">
                  <mat-form-field
                    class="example-form-field w-full"
                    appearance="fill"
                    class="per_mile"
                  >
                    <mat-label>Per Mile</mat-label>
                    <input
                      matInput
                      type="number"
                      min="1"
                      formControlName="vehicle_price_per_mile"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="example-form-field w-full"
                    appearance="fill"
                    class="per_hour"
                  >
                    <mat-label>Per Hour</mat-label>

                    <input
                      matInput
                      type="number"
                      min="1"
                      formControlName="vehicle_price_per_hour"
                    />
                  </mat-form-field>
                  <mat-form-field
                    class="example-form-field w-full"
                    appearance="fill"
                    class="per_hour"
                  >
                    <mat-label>Min</mat-label>

                    <input
                      matInput
                      type="number"
                      min="1"
                      formControlName="vehicle_min"
                    />
                  </mat-form-field>
                  <div
                    class="text-danger error b22 password m9"
                    *ngIf="
                      form.get('vehicle_price')?.touched &&
                      form.get('vehicle_price')?.errors?.required
                    "
                  >
                    Pricing is required field.
                  </div>
                </div>
              </div> -->
              <div class="flex gap-6 items-center">
                <div class="font-bold">Select image*:</div>
                <div>
                  <button
                    mat-flat-button
                    color="primary"
                    (click)="fileUpload.click(); showError = ''"
                    [class.spinner]="loading"
                    [disabled]="loading"
                  >
                    Upload Image here
                    <input
                      type="file"
                      #fileUpload
                      (change)="uploadImage($event)"
                      hidden
                    />
                  </button>
                </div>
              </div>
              <div
                *ngIf="selectedFile"
                style="position: relative; width: 200px"
              >
                <img
                  width="200px"
                  height="150"
                  [src]="previewUrl"
                  alt="Preview"
                />
                <mat-icon
                  (click)="previewUrl = ''; selectedFile = null"
                  style="
                    cursor: pointer;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    color: red;
                    font-size: 25px;
                    cursor: pointer;
                  "
                  >close</mat-icon
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="color: red">{{ showError }}</div>
      <div class="flex justify-center w-full">
        <div class="btn p-2">
          <button
            mat-flat-button
            color="primary"
            class="w-full"
            (click)="onSubmit()"
            [class.spinner]="loading"
            [disabled]="loading"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
